<template>
  <div>
    <div class="staff">
      <div class="left-top">
        <span style="font-size: 18px; font-weight: 400; color: #333"
          >周刊地址统计</span
        >
        <el-divider></el-divider>
      <el-form
        :model="searchForm"
        :inline="true"
        ref="searchForm"
        class="searchForm"
      >
        <el-form-item label="提交终端:">
          <el-select
            v-model="searchPartValue"
            style="width: 180px"
            @change="searchPart"
          >
            <el-option
              v-for="item in AlladdressType"
              :key="item.id"
              :value="item.value"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间:" prop="CreateTime">
            <el-date-picker
              v-model="releasetime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item>
        <el-form-item>
          <el-button @click="keysSearch(1)">
            <i class="el-icon-search" style="margin-right: 5px"></i>
            搜索
          </el-button>
          <el-button @click="resetForm()">
            <i class="el-icon-refresh-right" style="margin-right: 5px"></i>
            重置
          </el-button>
        </el-form-item>
      </el-form>
      </div>
      <el-table border :data="PageConfigure" row-key="CategoryGuid" style="width: 100%">
        <el-table-column fixed label="序号" width="80" type="index" align="center"></el-table-column>
        <el-table-column
          label="邮箱地址"
          prop="Email"
          align="center"
        >
        </el-table-column>
        <el-table-column label="提交终端" align="center">
        <template slot-scope="scope">
            <span v-if="scope.row.AddressType==1">PC</span>
            <span v-if="scope.row.AddressType==2">Mobile</span>
            <span v-if="scope.row.AddressType==3">IOS</span>
            <span v-if="scope.row.AddressType==4">Android</span>
        </template>
        </el-table-column>
        <el-table-column
          label="提交时间"
          prop="CreateTime"
          align="center"
        >
        </el-table-column>
      </el-table>
      <div class="task-list-page" v-if="PageConfigure !== ''">
        <el-pagination
          @current-change="OnCurrentPageChanged"
          @size-change="handleSizeChange"
          :current-page="Params.PageIndex"
          :page-sizes="[10, 20, 30, 40, 50, 60]"
          layout="prev, pager, next,sizes,jumper,total"
          :page-size="Params.PageSize"
          background
          :total="Params.TotalCount"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      dialogFormVisible: false,
      formLabelWidth: "100px",
      Rowcontent: {
        Id: "",
        Email: "",
        IsDelete: false,
        AddressType: "",
        CreateTime: "",
      },
     
      PageConfigure: [], //列表分页数据
      Params: {
        PageIndex: 1,
        PageSize: 10,
        TotalCount: 5,
        TotalPage: 0,
      },
      //选择日期
      releasetime:'',
      //解决重复点击问题
      isDisabled: false,
      //查询的时候 端口选择
      searchForm:{},
      AlladdressType:[
        {
          id:0,
          value:"全部",
        },
        {
          id:1,
          value:"PC",
        },
        {
          id:2,
          value:"Mobile",
        },
        {
          id:3,
          value:"IOS",
        },
        {
          id:4,
          value:"Android",
        }
      ],
      searchPartValue:'全部',
      
    };
  },
  mounted() {
    this.searchDate();
  },
  methods: {
    //防抖
    entime() {
      setTimeout(() => {
        this.isDisabled = false;
      }, 500);
    },
    //获取数据
    searchDate() {
      var _this = this;
      this.$get(_this.$api.WeeklyGetList, { ...this.Params })
        .then((res) => {
          console.log(res.PageIndex);
          console.log(res);
          _this.PageConfigure = res.Data;
          _this.Params.TotalCount = res.TotalNumber;
          _this.Params.TotalPage = res.TotalPage;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取分页
    OnCurrentPageChanged(val) {
      this.Params.PageIndex = val;
      console.log(this.Params.PageIndex);
      this.keysSearch();
    },
    handleSizeChange(val) {
      this.Params.PageSize = val;
      console.log(val);
      this.keysSearch();
    },
    //查询的时候
    searchPart(){
      var _this = this;
      this.AlladdressType.forEach(function (item, index) {
        //console.log(item.value == _this.searchPartValue)
        if (item.value == _this.searchPartValue) {
          _this.searchForm.addressType = item.id;
        }
      });
    },
    //查询
    keysSearch(num) {
      let _this = this;
      if (num == 1) {
        this.Params.PageIndex = 1;
      }
      if (this.releasetime) {
        this.searchForm.StartTime = this.releasetime[0];
        this.searchForm.EndTime = this.releasetime[1];
      }
      this.$get(this.$api.WeeklyGetList, {
        ...this.searchForm,
        ...this.Params,
      }).then((res) => {
        _this.PageConfigure = res.Data;
        _this.searchForm = {};
        _this.Params.TotalCount = res.TotalNumber;
        _this.Params.TotalPage = res.TotalPage;
      });
    },
    //重置查询
    resetForm() {
      this.searchPartValue = "全部";
      this.releasetime = "";
      this.Params.PageIndex = 1;
      this.searchDate();
    },
  },
};
</script>

<style lang="stylus" scoped>
/deep/.is-center.is-leaf {
  background: #e6e6e6;
  color: #303133;
}

/deep/.has-gutter tr {
  th {
    background: #e6e6e6;
    color: #303133;
  }

  td {
    text-align: center;
  }
}

.staff {
  background: #fff;
  padding: 10px 10px;
  border-radius: 10px;

  .searchForm {
    .el-form-item {
      vertical-align: middle;
      margin-top: 13px;
      margin-right: 30px;
    }
  }

  .backSpan {
    padding: 0px 20px;
    cursor: pointer;
  }

  .left-top {
    margin-bottom: 10px;

    .el-divider--horizontal {
      margin: 10px 0;
    }

    button {
      margin-left: 10px;
    }
  }

  .el-table .cell span {
    overflow: hidden; /* 超出部分隐藏 */
    white-space: nowrap; /* 不换行 */
    text-overflow: ellipsis; /* 超出部分文字以...显示 */
  }

  .task-list-page {
    margin-top: 10px;
  }
}

/* 编辑框样式 */
/deep/.edit-form {
  span.isedit {
    position: absolute;
    top: 10px;
    left: 185px;
    font-size: 22px;
    color: #fff;
  }

  width: 500px;

  .el-dialog__header {
    height: 50px;
    text-align: center;
    background-color: #409eff;

    .el-dialog__title {
      font-size: 22px;
      color: #fff;
      line-height: 0px;
      text-align: center;
    }

    .el-dialog__headerbtn {
      display: none;
    }
  }

  .el-form-item .el-textarea__inner {
    resize: none;
  }

  .el-dialog__body {
    padding-bottom: 5px !important;
  }

  .dialog-footer {
    text-align: center;

    .el-button {
      background-color: #fff;
      color: #606266;
      border: 1px solid #dcdfe6;
    }

    .el-button:hover {
      background-color: #409eff;
      border-color: #409eff;
      color: #fff;
    }
  }
}
</style>